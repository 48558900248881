@use 'settings.palette';

.image-gallery-thumbnail + .image-gallery-thumbnail {
  margin-left: 2px;
}

// Slideshow library css overrides
.image-gallery-content,
.image-gallery-slide,
.image-gallery-image {
  background: none;
  height: auto;
}

.image-gallery-content.fullscreen,
.image-gallery-content.fullscreen .image-gallery-slide,
.image-gallery-content.fullscreen .image-gallery-image {
  max-height: 100vh !important;
  height: auto;
}

.has-thumbnails {
  .image-gallery-content {
    .image-gallery-slide .image-gallery-image {
      max-height: calc(100vh - 90px) !important;
      aspect-ratio: 0.6666667;
    }
  }
  .image-gallery-content.fullscreen,
  .image-gallery-content.fullscreen .image-gallery-slide,
  .image-gallery-content.fullscreen .image-gallery-image {
    max-height: calc(100vh - 90px) !important;
    height: auto;
  }
}

.image-gallery-thumbnails-container {
  height: 80px !important;
}

.image-gallery-thumbnails {
  padding: 2px 0;
}

.image-gallery-thumbnail {
  width: fit-content;
  height: 80px;
  overflow: hidden;
  border: 2px solid #fff;
  transition: border 0.1s ease-in;

  .image-gallery-thumbnail-image {
    width: auto;
    height: 80px;
  }
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus {
  border: 2px solid settings.$color-core-brand-second-blue-80;
}

@media (hover: hover) and (pointer: fine) {
  .image-gallery-thumbnail:hover {
    outline: none;
    border: 2px solid settings.$color-core-brand-second-blue-80;
  }
}

.learning-block__gallery--square.learning-block__gallery--windowed {
  .image-gallery-thumbnail {
    width: 80px;
  }

  .image-gallery-content {
    .image-gallery-slide .image-gallery-image {
      max-height: none;
    }
  }

  .image-gallery-slide .image-gallery-image {
    object-fit: cover;
    aspect-ratio: 1/1;
    width: 100%;
  }
}

// Slideshow library css overrides for MedShrOpen
.image-gallery--medshr-open .image-gallery-content,
.image-gallery-slide,
.image-gallery-image {
  background: none;
}

.image-gallery--medshr-open .image-gallery-content.bottom {
  margin-bottom: 110px;
}
