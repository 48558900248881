@use 'settings.forms';
@use 'settings.palette' as settings2;

.autosuggest__control-container {
  position: relative;
}

.autosuggest__control {
  position: relative;
}

.autosuggest__toggle {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);

  background: #fff url(/static/media/images/caret@3x.png) no-repeat center right;
  background-size: 30px 11px;
  width: 30px;
  height: 1.5em;
  min-height: 11px;
  span {
    visibility: hidden;
  }
}

.autosuggest__clear {
  position: absolute;
  right: 40px;
  top: 50%;
  transform: translateY(-50%);

  width: 30px;
  height: 1.5em;
  min-height: 11px;
}

.autosuggest--open .autosuggest__input {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.autosuggest__list {
  position: absolute;
  top: 40px;
  width: 100%;
  border: 0;
  background-color: #fff;
  font-family: settings.$form-font-family;
  font-size: settings.$form-font-size;
  border-bottom-left-radius: settings.$form-border-radius;
  border-bottom-right-radius: settings.$form-border-radius;
  box-shadow: settings.$form-box-shadow-focus;
  z-index: 30;
}

.autosuggest--open .autosuggest__list {
  display: block;
  border: 1px solid #aaa;
  max-height: 15em;
  overflow-y: auto;
  z-index: 30;
}

.autosuggest__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.autosuggest__item {
  cursor: pointer;
  @apply tw-p-2;
}

.autosuggest__item--highlighted {
  background-color: settings2.$off-white;
  color: settings2.$color-core-brand-second-blue-60;
}
