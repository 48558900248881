@use 'settings.palette';
@use 'settings.type' as settings2;
@use 'tools.responsive';
@use 'tools.mixins' as mixins;

@use 'sass:math';

@include tools.media-query(lap-and-up) {
  .comments {
    margin-bottom: 40px;
  }
}

.comment-count {
  margin: 0;
  text-align: right;
}

.comment-count__amount {
  color: settings.$low-text-color;
}

.comment-count__new {
  color: settings.$color-status-error;
}

.comments__list {
  display: block;
  clear: left;

  @include tools.media-query(palm) {
    > div {
      position: relative !important;
    }
  }
}

.comment {
  background-color: settings.$content-background-color;
  color: settings.$mid-text-color;
  box-shadow: 1px 1px 0 rgba(0, 0, 0, 0);

  border-bottom: 1px solid settings.$off-white;

  padding: 15px 10px 15px;
  margin-bottom: 15px;

  &:last-child {
    background-color: settings.$content-background-color !important;
  }
}

.comment__content {
  display: flex;

  .comment__text,
  .profile-basic {
    flex-grow: 1;
  }
}
.comment__responses {
  align-self: flex-end;
}

.comment__author {
  .media__body {
    justify-content: center;
    height: 100%;
  }
}

.comment__tools {
  .dropdown__toggle {
    border: 0;
    background: transparent none;
    padding: 0 0 10px;
    display: block;
    outline: 0;
  }
  .icon {
    display: block;
  }
}

.comment__time {
  color: settings.$low-text-color;
  font-size: settings2.$small-font-size;

  &.comment {
    &:first-child {
      background-color: #f2f2f2;
    }
  }
}

.comment--with-tools .comment__time {
  top: 17px;
}

.comment__text {
  white-space: pre-line;
  margin-bottom: 15px;

  p {
    margin-bottom: 13px;
  }
}

.comment .dislike .icon-like {
  transform: rotate(180deg);
}

.comment__like-count {
  @apply tw-text-xs;
  display: inline-block;
  min-height: 24px;
  vertical-align: middle;
  cursor: pointer;
  padding-right: 10px;
  &:hover {
    text-decoration: underline;
  }
}

.comment__post-body {
  display: block;
  background: settings.$content-background-color;
  border: 0;
  @apply tw-text-ms-text-black;
  padding: 5px 10px;
  @apply tw-border tw-border-solid tw-border-ms-border-light-grey;
  border-radius: 6px;
  width: 100%;
  min-height: 120px;
  outline: none;
  resize: vertical;
  box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.comment__post-body::placeholder {
  @apply tw-text-ms-text-light-grey;
}

.comments__post {
  margin-top: 20px;
}

.comments__post-header {
  margin-right: 1rem;
}

.comments__post-footer {
  padding: 1rem 0 2rem;
  text-align: right;

  @include mixins.clearfix;

  .icon-spinner {
    margin: 5px 10px 0 0;
    fill: settings.$low-text-color;
  }

  .btn {
    float: right; // allows spinner top margin to be used to align with this button
  }
}

@include tools.media-query(palm) {
  .comments__post-footer {
    padding: 5px 10px;
  }
}

.comment__status {
  font-size: 90%;
  font-weight: bold;
  text-align: center;
}

.comment--hidden {
  border-top: 2px dashed #ccc;
  border-bottom: 2px dashed #ccc;
  opacity: 0.5;
}
