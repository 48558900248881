@use 'settings.palette';
@use 'tools.mixins';
@use 'tools.responsive' as tools2;

@use 'sass:math';
$mediumSpace: 11px;
$hover-menu-text-inset: 20px;

.user-menu {
  float: right;
}

.user-menu__link,
.user-menu__item {
  @apply tw-mr-4;

  @media (min-width: 784px) and (max-width: 1024px) {
    margin-right: $mediumSpace;
  }
}

.user-menu li:first-child .user-menu__link--text {
  border-left: 0;
}

.user-menu__link--text {
  @apply tw-pl-4;
  border-left: 1px solid settings.$light-link-color;
  color: settings.$light-link-color;
  display: inline-block;

  @media (min-width: 784px) and (max-width: 1024px) {
    padding-left: $mediumSpace;
  }
}

.user-menu__item--icon {
  position: relative;
  display: block;
  width: 24px;
  height: 20px;

  svg {
    position: absolute;
    top: 5px;
    cursor: pointer;
    fill: #fff;
  }

  &:last-child {
    margin-right: 0;
  }
}

.hover-menu {
  @include tools.block-menu--light();
  position: absolute;
  z-index: 100;
  top: 180%;
  right: 0;
  width: 16rem;
  @apply tw-py-2 tw-px-0;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);

  display: none;
}

.hover-menu--open {
  display: block;
}

// .user-menu__hover-menu:not(:hover):not(.user-menu__hover-menu--show) {
//   display: none;
// }

.user-menu__hover__menu__item--group-starter {
  @include tools.block-menu__item--group-starter(
    math.div($hover-menu-text-inset, 2)
  );
}

.user-menu__hover-menu__util-border {
  @include tools.block-menu__util-border(
    $hover-menu-text-inset,
    $hover-menu-text-inset
  );
}

.site-header a.user-menu__hover-menu__link,
.user-menu__hover-menu__link {
  @include tools.block-menu--light__item();
  padding: math.div($hover-menu-text-inset, 2) $hover-menu-text-inset;
}

.user-menu__hover-menu__link:hover {
  @include tools.block-menu--light__item--hover();
}

@include tools2.media-query(palm) {
  html {
    overflow-x: clip;
    width: 100vw;
    background-color: settings.$color-old-brand-dark-blue;
  }
  html body {
    transition: transform 0.26s ease;
    // width: 100vw;
  }
  html.with-main-menu body {
    transform: translateX(-15em);
  }

  .hover-menu-mobile {
    left: 100%;
    top: 0;
    position: absolute;
    background-color: settings.$color-old-brand-dark-blue;
    width: 15em;
  }

  .app-layout {
    background-color: settings.$base-background-color;
  }
}
