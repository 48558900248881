.media {
  display: flex;
  align-items: flex-start;
}

.media__img {
  @apply tw-mr-2 tw-shrink-0;
}

.media__body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.listing--media .media {
  @apply tw-mb-4;
}
