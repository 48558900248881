@use 'settings.palette';
@use 'settings.type' as settings2;
@use 'tools.mixins' as tools2;
@use 'tools.responsive';

@use 'sass:math';

$card-background-color: #fff;
$card-width: 220px;
$card-width-in-learning: 180px;
$card-h-spacing-unit: 50px;
$card-v-spacing-unit: math.div($card-h-spacing-unit, 2);
$card-title-z-index: 9;

$mobile-card-width: 300px;

.card {
  flex: 0 1 32%;

  display: flex;
  flex-direction: column;
}

@include tools.media-query(palm-small) {
  .card {
    flex: 0 1 49%;
  }
}

@include tools.media-query(palm-smallest) {
  .card {
    flex: 0 1 100%;
  }
}

@include tools.media-query(desk) {
  .card {
    flex: 0 1 24%;
  }
}

@include tools.media-query(large-desk) {
  .card {
    flex: 0 1 19%;
  }
}

.card {
  background-color: #fff;
  margin-bottom: 1em;
  box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.1);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.card__footer {
  flex-grow: 1;
}

.card--ad_card,
.card--group,
.card--case,
.card--user {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  width: 220px;
  @include tools.media-query(palm) {
    width: auto;
  }

  .card__resource {
    background-color: settings.$color-core-brand-first-blue-100;
    position: relative;
    width: 220px;
    height: 200px;
    @include tools.media-query(palm) {
      width: auto;
    }
  }

  .card__profile {
    padding: 7px 10px;
    min-height: 50px;

    .media__img {
      margin-top: 2px;
    }

    .media__title {
      display: flex;
      width: 100%;

      &:hover {
        text-decoration: none;
      }
    }

    .profile-basic__job-title {
      color: settings.$mid-text-color;
    }
  }

  .icon-poll {
    position: absolute;
    bottom: 8px;
    left: 8px;
  }

  .card__title {
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 1.2;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    //z-index: $card-title-z-index;
    height: 100%;
    overflow: hidden;

    .card__text--link-text {
      color: #fff;
      display: block;
      padding: 10px;
      height: 100%;
      position: relative;
      background-image: linear-gradient(
        rgba(0, 0, 0, 0.8),
        transparent,
        transparent
      );

      span {
        max-height: 100%;
        @include tools2.line-clamp(5, 16px, 19.2px);
        color: #fff;
        text-overflow: ellipsis;
      }
    }
  }

  // .card__text-link:hover {
  //   text-decoration: none;
  // }

  .card__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  // .card__type-icon {
  //   position: absolute;
  //   bottom: 10px;
  //   left: 10px;
  //   z-index: 10;
  // }

  // @include media-query(palm-portrait) {
  //   .card {
  //     width: 100%;
  //     margin-right: 0;
  //   }

  //   .card__resource {
  //     width: 100%;
  //     height: $card-width;
  //     overflow: hidden;
  //   }

  //   .card__image {
  //     width: 100%;
  //     height: $mobile-card-width;
  //   }
  // }

  // @include media-query(palm-landscape) {
  //   .u-1-of-2-palm-landscape,
  //   .u-1-of-1 {
  //     .card {
  //       width: 100%;
  //     }

  //     .card__resource,
  //     .card__image {
  //       width: 100%;
  //     }
  //   }
  // }

  // @include media-query(lap) {
  //   .u-1-of-1 {
  //     .card {
  //       width: $mobile-card-width;
  //       margin-right: 0;
  //     }

  //     .card__resource {
  //       width: $mobile-card-width;
  //       height: $card-width;
  //       overflow: hidden;
  //     }

  //     .card__image {
  //       width: $mobile-card-width;
  //       height: $mobile-card-width;
  //       margin-top: math.div(($mobile-card-width - $card-width), 2);
  //     }
  //   }
  // }

  .badge {
    position: absolute;
    right: 0.5em;
    bottom: 0.5em;
    // z-index: ($card-title-z-index + 1);
    @apply tw-text-xs;
    height: auto;
    pointer-events: none;
  }

  // .card__row {
  //   margin: -5px 10px;
  // }

  // .card__profile {
  //   height: 48px; // same height even if some child elements are empty
  // }

  .card__footer {
    @apply tw-p-2;
    position: relative;
    height: 133px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .btn--wide {
      padding-left: 0;
      padding-right: 0;
      width: 100%;
    }
  }

  .card__followers {
    @apply tw-mb-2;
    .profile-list {
      margin: 0;
    }
  }

  // .card--case .card__footer {
  //   height: 141px; // same height even if some child elements are empty
  // }

  // .card--without-actions .card__footer {
  //   height: 95px;
  // }

  // .card--without-followers .card__footer {
  //   height: 100px;
  // }

  // .card--my-case .card__footer {
  //   height: 50px;
  // }

  // .card--with-stats .card__footer {
  //   height: 75px;
  // }

  .card__links,
  .card__reason,
  .card__stats {
    @apply tw-text-xs tw-text-ms-text-grey;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .card__reason {
    font-weight: 400;
    word-break: break-word;
    margin-bottom: 7px;
  }

  .card__reason .editor-paragraph {
    font-weight: 400;
    margin-bottom: 0;
    word-break: break-word;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .card__stats .profile-list {
    float: left;
  }

  .card__stats__numbers {
    padding-top: 6px;
    display: block;
    float: left;
  }

  // .card__action {
  //   margin-top: $half-spacing-unit;
  //   padding-top: $half-spacing-unit;
  //   border-top: 1px solid $off-white;
  //   text-align: center;
  //   position: absolute;
  //   left: 20px;
  //   bottom: $half-spacing-unit;
  //   right: 20px;
  // }
}

.card-list .card--learning {
  width: 220px;
  @include tools.media-query(palm) {
    width: auto;
  }
}

.card,
.learning-card {
  justify-content: space-between;
}

.learning-card__resource {
  height: 132px;
  position: relative;

  display: block;
  background-position: 50% 50%;
  background-size: cover;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  cursor: pointer;
}

.learning-card__label {
  color: white;
  font-weight: 600;
  font-size: 12px;
  line-height: 1.75rem;
  padding: 0.25rem 0.75rem;
  background-color: #3178c4;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  top: 0.5rem;
  left: 0;
  position: absolute;
}

.learning-card__label--accreditor {
  top: 3.2rem;
  background-color: #58368a;
}

.learning-card__title-section {
  padding: 5px 10px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2px;
  display: flex;
  width: 100%;
  background: #09336a;
}

.learning-card__title {
  color: #fff;
  display: block;
  width: 100%;
  height: 60px;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  word-wrap: break-word;
  text-align: left;

  overflow: hidden;

  &:hover {
    text-decoration: none;
  }

  span {
    max-height: 100%;
    @include tools2.line-clamp(3, 16px, 19.2px);
    text-overflow: ellipsis;
  }
}

.learning-card__meta {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
}

.learning-card__meta,
.learning-card__footer {
  font-size: settings2.$small-font-size;
  padding: 5px 10px;
  line-height: settings2.$small-font-size * settings2.$lineheight-close;
}

.learning-card__meta-row {
  @include tools2.line-clamp(
    2,
    settings2.$small-font-size,
    settings2.$small-font-size * settings2.$lineheight-close
  );
  margin: 5px 0;
}

.learning-card__meta-row--series-sub {
  display: flex;
  justify-content: space-between;
}

.learning-card__footer-meta {
  display: flex;
  justify-content: space-between;
  color: rgba(0, 0, 0, 0.55);
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  padding-top: 5px;
}
.learning-card__footer-meta__right {
  display: flex;
  flex-direction: column;
  text-align: right;
}

.learning-card__buttons {
  display: flex;
  padding: 10px 0;
  font-size: settings2.$small-font-size;
  line-height: settings2.$small-font-size * settings2.$lineheight-close;
  .btn {
    white-space: nowrap;
  }
  .btn,
  form {
    margin-right: 5px;
    width: 100%;
  }
  .btn:last-child,
  form:last-child {
    margin-right: 0;
  }
}

.price-label-container {
  position: absolute;
  z-index: $card-title-z-index;
  bottom: 0.5rem;
  right: 0;
  min-width: 47px;
  height: 29px;
  background-color: gold;
  border: 2px solid white;
  border-right: 0;
  border-bottom-left-radius: 15px;
  border-top-left-radius: 15px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
  white-space: nowrap;
}

.price-label-text {
  color: black;
  font-size: 15px;
  font-weight: 600;
  font-family: 'Inter', sans-serif;
}

.card-item--ad_image {
  flex: 1 0 100%;
  .ad_image {
    text-align: center;
    margin: 0 auto 2em;
  }
}
