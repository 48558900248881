@use 'settings.palette';
@use 'tools.responsive';

.social-signin {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.social-signin__item {
  padding: 15px 25px 20px;
  border-radius: 8px;
  border: 1px solid settings.$color-text-grey-40;
}

@include tools.media-query(portable) {
  .social-signin__item {
    padding: 10px 20px 15px;
  }
}

@media screen and (max-width: 379px) {
  .social-signin__item {
    padding: 10px 15px 15px;
  }
}
