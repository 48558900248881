.tabs {
  display: flex;
  a {
    @apply tw-block tw-py-4;
  }
}

.fullwidth-tabs {
  background-color: #7c8899;

  .tabs {
    width: 100%;
    justify-content: stretch;
    li {
      flex: 1;
      text-align: center;
    }
  }

  a.tabs__tab {
    @apply tw-text-2xl;
    color: #fff;
    &:hover {
      color: #fff;
    }
  }
  a.tabs__tab--active {
    @apply tw-bg-ms-base-bg tw-text-ms-text-black;
    &:hover {
      @apply tw-text-ms-text-black;
    }
  }
}
