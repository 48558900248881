@use 'settings.palette';
@use 'settings.template' as settings2;
@use 'tools.responsive';

.social-share {
  margin: 0;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.social-share__icon {
  width: 30px;
  height: 30px;
  border: 2px solid settings.$color-core-brand-second-blue-80;
  border-radius: 200px;
  cursor: pointer;
  margin-right: 10px;
  position: relative;
  margin: 0.5rem !important;

  span {
    display: block;
    width: 100%;
    height: 100%;
  }

  .icon {
    fill: settings.$color-core-brand-second-blue-80;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.dark-background .social-share__icon {
  border: 2px solid #fff;

  .icon {
    fill: #fff;
  }
}

.movie-page {
  .social-share {
    top: 25px;
    z-index: settings2.$modal-backdrop-z-index + 10;
  }
}

@include tools.media-query(palm) {
  .social-share--has-webshare {
    .social-share__icon {
      display: none;
    }
    .social-share__icon--webshare {
      display: block;
    }
  }
}
