@use 'settings.palette';
@use 'tools.mixins';

.dropdown {
  position: relative;
}

.dropdown__toggle {
  z-index: 100;
  .icon-more {
    fill: settings.$link-color;
    stroke: none;
  }
}

.dropdown__content {
  display: none;
  opacity: 0;
  transition: opacity 0.26s ease-in-out;
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 1000;
  background-color: settings.$content-background-color;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  min-width: 10em;
  max-width: 100%;
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    a {
      @include tools.block-menu--light__item();
      padding: 5px 10px;
      &:hover {
        @include tools.block-menu--light__item--hover();
      }
    }
  }
}

.dropdown__content:focus,
.dropdown:focus .dropdown__content,
.dropdown__content:hover,
.dropdown:hover .dropdown__content {
  display: block;
  opacity: 1;
}
