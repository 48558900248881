@use 'settings.palette';

.featured-in-groups__title {
  @apply tw-text-sm tw-mb-2 tw-text-ms-text-grey;
}

.cms-block--post-metadata h1 {
  @apply tw-mb-4;
}

.cms-block--post-metadata__stats {
  @apply tw-flex tw-text-sm tw-items-center tw-mb-4;
}

.cms-block--post-metadata__followers {
  @apply tw-grow tw-flex tw-items-center;
}

.cms-block--post-metadata__followers__more {
  @apply tw-ml-2;
}

.vote-to-discuss {
  background-color: settings.$color-core-brand-first-blue-60;
  color: #fff;
  @apply tw-text-lg tw-p-4 tw-mb-8;
}

.specialty-tags {
  overflow: hidden;
  text-overflow: ellipsis;
  @apply tw-text-sm/10;
}

p.specialty-tags {
  @apply tw-mb-4;
}

.specialty-tags__tag {
  @apply tw-bg-ms-deep-bg tw-text-ms-text-black tw-py-2 tw-px-4 tw-mr-2 tw-rounded-full;
  white-space: nowrap;
}
