@use 'sass:color';
@use 'settings.palette' as settings2;
@use 'settings.type';
@use 'tools.responsive';

@use 'sass:math';
.btn {
  display: inline-block;
  vertical-align: middle;
  font-family: inherit;
  font-size: 100%;
  line-height: settings.$lineheight-m;
  text-align: center;
  cursor: pointer;
  border: none;
  margin: 0;
  padding: 0.5em 1em;
  text-decoration: none;
  border-radius: 200px;
  outline: none;

  &:focus {
    outline: 0;
    box-shadow: 0 1px 5px 0px rgba(#999, 0.6);
  }

  &:active,
  &:focus,
  &:hover {
    text-decoration: none;
  }

  svg,
  img {
    display: inline-block;
  }

  .icon:last-child {
    margin-left: 0.25em;
  }
}

@include tools.media-query(lap-and-up) {
  .no-touchevents .btn:not(.btn--disabled, [disabled]):hover {
    text-decoration: none;
  }
}

@mixin btn-foreground($color) {
  color: $color;
  .icon__content {
    stroke: $color;
    fill: $color;
  }
}

.btn {
  @include btn-foreground(settings2.$link-color);
  background-color: transparent;
  border: 2px solid;
  transition:
    background-color 0.1s ease-in-out,
    color 0.1s ease-in-out,
    border-color 0.1s ease-in-out,
    opacity 0.1s ease-in-out;
}

@include tools.media-query(lap-and-up) {
  .btn:not(.btn--disabled, [disabled]).ui-state-hover,
  .no-touchevents .btn:not(.btn--disabled, [disabled]):hover {
    @include btn-foreground(#fff);
    background-color: settings2.$link-color;
    border-color: settings2.$link-color;
  }
}

.btn--square {
  border-radius: 6px;
}

.no-touchevents .btn--off:hover {
  opacity: 1;
}

.btn--block {
  width: 100%;
}

.btn--fill {
  @include btn-foreground(#fff);
  background-color: settings2.$color-core-brand-second-blue-100;
  border-color: settings2.$color-core-brand-second-blue-100;
}

.btn--primary {
  @include btn-foreground(#fff);
  background-color: settings2.$color-core-brand-second-blue-100;
  border-color: settings2.$color-core-brand-second-blue-100;
}

@include tools.media-query(lap-and-up) {
  .btn--primary:not(.btn--disabled, [disabled]).ui-state-hover,
  .no-touchevents .btn--primary:not(.btn--disabled, [disabled]):hover {
    @include btn-foreground(#fff);
    background-color: color.adjust(
      settings2.$color-core-brand-second-blue-100,
      $lightness: -10%
    );
    border-color: color.adjust(
      settings2.$color-core-brand-second-blue-100,
      $lightness: -10%
    );
  }
}

.btn--secondary {
  @include btn-foreground(#fff);
  background-color: settings2.$color-core-brand-accent-100;
  border-color: settings2.$color-core-brand-accent-100;
}

@include tools.media-query(lap-and-up) {
  .btn--secondary:not(.btn--disabled, [disabled]).ui-state-hover,
  .btn--secondary:not(.btn--disabled, [disabled]):hover,
  .no-touchevents .btn--secondary:not(.btn--disabled, [disabled]):hover {
    @include btn-foreground(#fff);
    background-color: color.adjust(
      settings2.$color-core-brand-accent-100,
      $lightness: -10%
    );
    border-color: color.adjust(
      settings2.$color-core-brand-accent-100,
      $lightness: -10%
    );
  }
}

.dark-background {
  .btn {
    @include btn-foreground(#fff);
  }

  .btn--secondary {
    @include btn-foreground(settings2.$color-core-brand-first-blue-100);
    background-color: settings2.$color-core-brand-accent-60;
    border-color: settings2.$color-core-brand-accent-60;
  }

  @include tools.media-query(lap-and-up) {
    .btn--secondary:not(.btn--disabled, [disabled]).ui-state-hover,
    .btn--secondary:not(.btn--disabled, [disabled]):hover,
    .no-touchevents .btn--secondary:not(.btn--disabled, [disabled]):hover {
      @include btn-foreground(settings2.$color-core-brand-first-blue-100);
      background-color: color.adjust(
        settings2.$color-core-brand-accent-60,
        $lightness: -10%
      );
      border-color: color.adjust(
        settings2.$color-core-brand-accent-60,
        $lightness: -10%
      );
    }
  }
}

.btn--danger {
  color: #fff;
  background-color: settings2.$color-status-error;
  border-color: settings2.$color-status-error;
  &.btn--active,
  &.btn--active--disabled,
  &:active,
  &:hover {
    background-color: settings2.$color-status-error !important;
    border-color: settings2.$color-status-error !important;
  }
}

@include tools.media-query(lap-and-up) {
  .btn--danger:not(.btn--disabled, [disabled]).ui-state-hover,
  .btn--danger:not(.btn--disabled, [disabled]).btn--active,
  .btn--danger:not(.btn--disabled, [disabled]).btn--active--disabled,
  .btn--danger:not(.btn--disabled, [disabled]):active,
  .no-touchevents .btn--danger:not(.btn--disabled, [disabled]):hover {
    @include btn-foreground(#fff);
    background-color: color.adjust(
      settings2.$color-status-error,
      $lightness: -10%
    ) !important;
    border-color: color.adjust(
      settings2.$color-status-error,
      $lightness: -10%
    ) !important;
  }
}

.btn-list {
  .btn {
    margin-right: 1em;
  }
  .btn:last-child {
    margin-right: 0;
  }
}

.btn--cta {
  display: block;
  font-weight: bold;
  padding-top: 1em;
  padding-bottom: 1em;
  @include tools.media-query(lap) {
    font-size: settings.$large-font-size;
  }
  @include tools.media-query(desk-and-up) {
    font-size: settings.$x-large-font-size;
  }
}

.btn--continue {
  position: relative;
  height: 55px;

  .continue-arrow {
    position: absolute;
    right: 3px;
    top: 3px;
  }
}

.btn--small {
  font-size: settings.$small-font-size;
}

.btn--off,
.btn[disabled],
.btn--disabled {
  cursor: default;
  @include btn-foreground(settings2.$color-text-grey-80);
  border-color: settings2.$color-text-grey-60;
  &.btn--fill {
    @include btn-foreground(#eee);
    background-color: settings2.$color-text-grey-60;
  }
}

.btn--primary[disabled],
.btn--secondary[disabled],
.btn--cta[disabled] {
  opacity: 0.5;
  color: #fff;
}

form.action-button {
  display: inline-block;
  margin: 0 0.5em;
}
