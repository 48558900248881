@use 'sass:color';
@use 'settings.grid' as settings2;
@use 'settings.palette';
@use 'settings.responsive' as settings3;
@use 'tools.responsive';

.homepage {
  background-color: settings.$color-core-brand-first-blue-100;
  color: #fff;
  min-height: 100vh;
  a:not(.btn) {
    color: settings.$color-core-brand-accent-60;
    text-decoration: underline;
  }
  a:not(.btn):hover {
    color: color.adjust(settings.$color-core-brand-accent-60, $lightness: -10%);
  }

  p {
    @apply tw-mb-4;
  }
}

.homepage__header {
  position: relative;
  // background: transparent url(https://static.medshr.net/lander/images/header-desktop.jpg) no-repeat 50% 50%;
  background-size: cover;
  height: 230px;
  margin-bottom: 50px;
  img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    max-width: 100%;
    height: 230px;
    object-fit: cover;
  }
}

.homepage__header-content {
  position: relative;
  background: url(https://static.medshr.net/lander/images/header-corner.svg)
    no-repeat bottom right;
  background-size: 100px;
  height: 230px;
  display: flex;
  align-items: center;
  h1 {
    width: 100%;
    max-width: 1248px;
    margin: 0 auto 30px;
    padding: 0 settings2.$grid-gutter-desk;
    height: 150px;

    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .site-header__logo-link {
    padding: 0 0 60px;
  }
}

@include tools.media-query(desk) {
  .homepage__header-content {
    h1 {
      max-width: 992px;
    }
  }
}

@include tools.media-query(portable) {
  .homepage__header {
    // background: transparent url(https://static.medshr.net/lander/images/header-tablet.jpg) no-repeat 50% 50%;
    background-size: cover;
    height: 230px;
  }
  .homepage__header-content {
    background: url(https://static.medshr.net/lander/images/header-corner.svg)
      no-repeat bottom right;
    background-size: 100px;
    height: 230px;
    h1 {
      max-width: 768px;
      padding: 0 settings2.$grid-gutter-desk;
      margin: 0 auto;
    }
  }
}

@include tools.media-query(palm-medium) {
  .homepage__header {
    // background: transparent url(https://static.medshr.net/lander/images/header-mobile.jpg) no-repeat 50% 50%;
    background-size: cover;
    height: 238px;
  }
  .homepage__header-content {
    background: url(https://static.medshr.net/lander/images/header-corner.svg)
      no-repeat bottom right;
    background-size: 100px;
    height: 238px;
    img {
      height: 238px;
    }
    h1 {
      max-width: settings3.$breakpoint-palm-medium +
        (2 * settings2.$grid-gutter-palm);
      padding: 0 settings2.$grid-gutter-palm;
      margin: 0 auto;
      line-height: 1;
    }
  }
}

@include tools.media-query(palm-small) {
  .homepage__header {
    // background: transparent url(https://static.medshr.net/lander/images/header-mobile.jpg) no-repeat 50% 50%;
    background-size: cover;
    height: 278px;
    img {
      height: 278px;
    }
  }
  .homepage__header-content {
    background: url(https://static.medshr.net/lander/images/header-corner.svg)
      no-repeat bottom right;
    background-size: 100px;
    height: 278px;
    h1 {
      height: 100%;
      margin: 0;
      width: 90%;
      padding: 0 settings2.$grid-gutter-desk;
      line-height: 1.2;
    }
  }
}

.homepage-section {
  margin: 20px auto 30px;
}

p.homepage__free-learning {
  margin-bottom: 30px;
}

.homepage-section:last-child {
  margin-bottom: 0;
  padding-bottom: 50px;
}

.homepage-reason {
  padding: 0 10px;
  h2 {
    margin: 1em 0;
  }
  h2:first-child {
    margin-top: 0.5em;
  }
  max-width: 100%;
  p {
    max-width: 20em;
    margin-left: auto;
    margin-right: auto;
  }
}

.homepage-section {
  .homepage-reason {
    padding: 10px;
  }
  .homepage-reason:last-child {
    p:last-child {
      margin-bottom: 0;
    }
  }
}

.homepage-awards {
  width: 550px;
  display: flex;
  align-items: center;
  border: 1px solid settings.$color-text-grey-10;
  border-radius: 12px;
  padding: 10px 10px 15px;
}

@include tools.media-query(palm) {
  .homepage-awards {
    width: 300px;
    display: block;
    border: 0;
    padding: 0;
  }
  .homepage-awards-list {
    width: 100%;
  }
}

.homepage-awards-list {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-top: 20px;
  img {
    margin: 0 0 15px 0;
  }
}

.btn--home-signup {
  padding: 0.75em 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  span + span {
    margin-top: 1em;
  }
}
