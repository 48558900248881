@use 'settings.palette';
@use 'tools.responsive';

.badgeable {
  position: relative;
}

.badge {
  position: absolute;
  border-radius: 100px;
  color: #fff;
  background-color: settings.$color-core-brand-second-blue-80;
  text-align: center;
  transition: all 0.2s ease-out;
  white-space: nowrap;

  min-width: 18px;
  height: 18px;
  padding: 1px 4px;
  font-size: 10px;
}

.badge-scale-transition-enter {
  .badge {
    opacity: 0;
    transform: scale(0);
  }
}

.badge-scale-transition-enter.badge-scale-transition-enter-active {
  .badge {
    opacity: 1;
  }
}

.badge-scale-transition-leave {
  .badge {
    opacity: 1;
  }
}

.badge-scale-transition-leave.badge-scale-transition-leave-active {
  .badge {
    opacity: 0;
    transform: scale(0);
  }
}

.badge--wide {
  @apply tw-py-1 tw-px-4;
}

.card .badge {
  background-color: settings.$color-core-brand-first-blue-60;
}
.card .badge--seen,
.badge--seen {
  background-color: settings.$color-core-brand-first-blue-60;
}

.card .badge--new,
.badge--new {
  background-color: settings.$color-core-brand-second-blue-100;
}

.card .badge--urgent,
.badge--urgent {
  background-color: settings.$color-status-alert;
}

@include tools.media-query(lap-and-up) {
  .site-header .badge {
    top: -12px;
    right: -12px;
    border: 1px solid #fff;
    min-width: 17px;
    height: 17px;
  }

  .site-header .user-menu__item--profile .badge {
    right: 4px;
  }
}
