@use 'settings.palette';

.profile-basic__name {
  @apply tw-text-sm tw-mb-2;
}

.profile-basic__stats,
.profile-basic__job-title {
  @apply tw-text-xs tw-text-ms-text-grey tw-line-clamp-1;
}

.profile-basic__institution {
  @apply tw-text-xs tw-text-ms-text-light-grey tw-line-clamp-1;
}

.profile-basic--highlighted {
  img {
    border: 2px solid settings.$color-core-brand-second-blue-80;
  }

  .profile-basic__name {
    font-weight: 600;
  }
}
