@use 'settings.palette';
@use 'settings.type' as settings2;

$form-field-border-color: transparent;
$form-input-background-color: #fff;
$form-input-text-color: settings.$base-text-color;
$auth-form-input-background-color: settings.$color-text-grey-10;
$auth-form-input-text-color: settings.$color-core-brand-first-blue-60;
$form-border-color: settings.$color-text-grey-10;
$form-border-color-hover: settings.$color-text-grey-40;
$form-border-color-focus: settings.$color-text-grey-40;
$form-border-color-error: settings.$color-status-error-light;
$form-border-radius: 4px;
$auth-form-border-radius: 20px;
$form-box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.1);
$form-box-shadow-focus: none;
$form-font-size: settings2.$small-font-size;
$form-font-family: settings2.$brand-face;
$auth-form-font-size: settings2.$base-font-size;
