.profile-list {
  white-space: nowrap;
}
.cms-page ul.profile-list {
  margin: 0;
}

.profile-list__profile {
  margin-right: 6px;
}

.profile-list__plus-more {
  @apply tw-text-xs tw-text-ms-text-grey;
}

.profile-list > li {
  vertical-align: middle;
}
