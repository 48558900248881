@use 'sass:list';
@use 'settings.responsive';
@use 'tools.hide';
@use 'tools.responsive' as tools2;

.debug {
  display: none !important;
}

.accessibility,
.visually-hidden,
%visually-hidden,
.hide {
  @include tools.visually-hidden();
}
.display-none {
  display: none !important;
}

@each $breakpoint in settings.$breakpoints {
  $alias: list.nth($breakpoint, 1);
  .only-#{$alias} {
    display: none;
  }
}

@each $breakpoint in settings.$breakpoints {
  // Grab the alias and the condition from their respective locations in
  // the list.
  $alias: list.nth($breakpoint, 1);
  $condition: list.nth($breakpoint, 2);

  @include tools2.media-query($alias) {
    .hide-#{$alias} {
      @include tools.visually-hidden();
    }
    .not-#{$alias} {
      display: none !important;
    }
    .only-#{$alias} {
      display: block !important;
    }
    span.only-#{$alias} {
      display: inline !important;
    }
  }
}

.fade {
  transition: opacity 0.26s ease-in;
}

.fade--out {
  opacity: 0;
}

.fade--in {
  opacity: 1;
}
