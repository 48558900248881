@use 'tools.responsive';
@use 'tools.mixins' as mixins;

.cms-page {
  h1,
  h2,
  h3,
  p,
  ul,
  ol {
    margin-bottom: 1em; // em not rem so margin is relative to font-size
  }

  h1 {
    @apply tw-text-3xl tw-font-bold;
  }

  h2 {
    @apply tw-text-2xl tw-font-semibold;
  }

  h3 {
    @apply tw-text-lg tw-font-semibold;
  }

  ul {
    @apply tw-list-disc tw-list-inside;
    p {
      display: inline;
    }
  }

  ol,
  ul {
    ol,
    ul {
      margin: 0.25em 0 0.5em 1.5em;
    }
    ul {
      list-style-type: circle;
      ul {
        list-style-type: square;
      }
    }
  }

  ol {
    @apply tw-list-decimal tw-list-inside;
    p {
      display: inline;
    }
  }

  hr {
    border-color: rgba(0, 0, 0, 0.15);
    margin: 3rem 0;
    clear: both;
  }

  footer {
    @apply tw-mt-8;
  }

  .page-updated-at {
    @apply tw-text-ms-text-grey;
    svg {
      fill: rgb(49, 69, 89);
      @apply tw-mr-1;
    }
  }

  blockquote {
    padding: 1em 2.5em 2em;
    margin-bottom: 1em;
    position: relative;
    display: flex;
    &:before {
      content: '“';
      top: -0.25em;
      left: -10px;
    }
    &:after {
      content: '”';
      top: -0.25em;
      right: -10px;
    }
    &:before,
    &:after {
      font-size: 100px;
      color: rgba(150, 150, 150, 0.4);
      position: absolute;
      font-family: Georgia, serif;
    }

    .learning-block__block_quote__text {
      flex-grow: 1;
    }
  }

  blockquote footer {
    text-align: right;
    font-size: 14px;
    position: absolute;
    bottom: 0;
    right: 1em;
  }

  @include tools.media-query(lap-and-up) {
    .cms-columns {
      display: flex;
    }

    .cms-column {
      width: 50%;
      margin-left: 2em;
    }

    .cms-column--0 {
      margin-left: 0;
    }
  }

  .cms-document-block {
    @apply tw-block tw-w-full tw-mb-4;
  }

  .cms-document-block.centered {
    margin-left: auto;
    margin-right: auto;
  }

  .cms-document-block--float-left {
    float: left;
    @apply tw-mr-4;
  }

  .cms-document-block--float-right {
    float: right;
    @apply tw-ml-4;
  }

  .cms-document-block_img--fill-x {
    width: 100%;
  }

  .cms-document-block__img--fill-x-crop-y {
    width: 100%;
    max-height: 300px;
    object-fit: cover;
  }

  .cms-document-block {
    clear: both;
    float: none;
    margin: 0 auto 1em;
    max-width: 100%;
  }

  .cms-document-block__caption {
    @apply tw-text-sm tw-bg-gray-200;
  }

  @include tools.media-query(lap-and-up) {
    .document--fill {
      float: none;
      img {
        display: block;
        margin: 0 auto;
        max-width: 100%;
        max-height: none !important;
      }
    }

    .document--center {
      float: none;
      margin: 0 auto 1em;
      max-width: 100%;
      width: 38%;
    }

    .document--float-left {
      float: left;
      margin: 0 1em 1em 0;
      max-width: 38%;
    }

    .document--float-right {
      float: right;
      margin: 0 0 1em 1em;
      max-width: 38%;
    }
  }

  .cms-youtube-block {
    display: block;
    margin: 0 auto 1em;
    max-width: 100%;
  }

  .cms-iframe-block {
    display: block;
    margin: 0 auto 2em;
    max-width: 100%;
  }

  .cms-open-graph-block {
    box-shadow: 0 1px 4px 2px rgba(0, 0, 0, 0.1);
    display: block;
    @apply tw-mb-4;
  }

  .cms-open-graph-block--small {
    display: flex;

    img {
      width: 300px;
    }
  }

  .cms-open-graph-block__title {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 0.5em;
  }

  .cms-document-block__caption,
  .cms-open-graph-block__about {
    background-color: #e2e2e6;
    @apply tw-p-2;
  }

  .cms-medshr-feed {
    clear: both;
  }

  .cms-medshr-feed {
    @apply tw-grid tw-gap-4 sm:tw-grid-cols-2 md:tw-grid-cols-3 lg:tw-grid-cols-4 xl:tw-grid-cols-5;
  }

  @include tools.media-query(lap-and-up) {
    .cms-column {
      .cms-medshr-feed {
        @apply tw-grid tw-gap-4 md:tw-grid-cols-1 lg:tw-grid-cols-2 xl:tw-grid-cols-2;
      }
    }
  }

  .rt-style-underline {
    text-decoration: underline;
    font-weight: inherit;
  }

  .rt-style-underline-body-size {
    text-decoration: underline;
    font-size: 100% !important;
    margin-bottom: 0;
  }
  h1.rt-style-underline-body-size,
  h2.rt-style-underline-body-size,
  h3.rt-style-underline-body-size,
  h4.rt-style-underline-body-size {
    font-weight: bold;
  }

  a.rt-style-external:after {
    content: ' ';
    display: inline-block;
    margin-left: 0.25em;
    width: 14px;
    height: 14px;
    background: transparent
      url(/assets/20230901-1693555955/app/styles/images/external-link.svg) 50%
      50% no-repeat;
    background-size: 100%;
  }

  .learning-block__gallery-wrapper {
    margin-bottom: 2em;
  }

  .learning-block__document-gallery-wrapper {
    clear: both;
    border: solid 1px rgba(0, 0, 0, 0.1);
    padding: 10px;
    margin-bottom: 2em;
    background: rgba(0, 0, 0, 0.03);
    border-radius: 5px;
  }

  .learning-block__document__image-gallery-wrapper {
    border: solid 1px rgba(0, 0, 0, 0.1);
    background: white;
  }

  .learning-block__document__image-gallery-controls-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
    width: 100%;
    // padding: 10px;
  }

  $image-gallery-breakpoint: 560px;

  .learning-block__document__image-gallery-page-controls-wrapper {
    display: inline-flex;
    background: rgba(0, 0, 0, 0.04);
    padding: 5px 10px;
    align-items: center;
    border-radius: 5px;
    border: solid 1px rgba(0, 0, 0, 0.1);
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;

    @apply tw-text-sm;

    @media screen and (max-width: $image-gallery-breakpoint) {
      justify-content: center;
    }
  }

  .learning-block__document__image-gallery-arrow-controls-wrapper {
    display: flex;
    align-items: center;
    @media screen and (max-width: $image-gallery-breakpoint) {
      width: 100%;
      justify-content: center;
      padding: 5px;
    }

    .btn {
      display: flex;
      svg,
      svg:last-child {
        margin-left: 0;
      }
    }
  }

  .learning-block__document__image-gallery-goto-page-wrapper {
    display: flex;
    align-items: center;
    @media screen and (max-width: $image-gallery-breakpoint) {
      width: 100%;
      justify-content: center;
      padding: 5px;
    }

    input {
      width: 50px;
      height: 30px;
      margin-left: 6px;
      padding: 0 5px;
    }

    .btn {
      display: flex;
    }
  }

  .learning-block__document__image-gallery-page-controls-text {
    margin: 0px 15px;
    white-space: nowrap;
  }

  .learning-block__document__image-gallery-goto-page-text {
    white-space: nowrap;
  }

  .learning-block__document__image-gallery-button {
    border-radius: 3px;
    background: #3489db;
  }

  .learning-block__video-wrapper {
    clear: both;
    margin-bottom: 1em;
  }

  .learning-block__video-youtube__aspect-ratio-wrapper {
    clear: both;
    margin-bottom: 1em;
    position: relative;
    padding-bottom: calc(
      var(--aspect-ratio, 0.5625) * 100%
    ); // 16:9 aspect-ratio
    height: 0px;
  }

  .learning-block__video-youtube__iframe {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }

  .cms-reference-link {
    text-decoration: none;
  }

  .cms-block--pubmed-article {
    @apply tw-px-3 tw-py-3 tw-border tw-border-solid tw-border-ms-border-light-grey tw-rounded-sm tw-mb-4 tw-clear-both;
  }

  .cms-block--pubmed-article__authors {
    @apply tw-text-xs tw-font-semibold;
  }

  .cms-block--pubmed-article__abstract {
    @apply tw-text-sm tw-mt-2 tw-px-3 tw-py-2 tw-bg-gray-200 tw-rounded-sm;
    p:last-child {
      @apply tw-mb-2;
    }
  }
}
