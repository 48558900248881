@use 'settings.palette';
@use 'settings.type' as settings2;
@use 'tools.responsive';

@include tools.media-query(palm) {
  .learning-search-header {
    position: relative;

    background: url(/static/images/learning-lander-search-bg-palm.jpg) no-repeat
      50% 50%;
    background-size: cover;

    &:before {
      content: '';
      z-index: 1;
      position: absolute;
      width: 100%;
      top: 0;
      bottom: 0;
      background-color: rgba(settings.$color-core-brand-first-blue-100, 0.5);
    }

    h1 {
      position: relative;
      z-index: 2;
      color: #fff;
      width: 90%;
      max-width: 20em;
      margin: 0 auto;
      padding: 1em 0 1.5em;
      text-align: center;
      font-size: settings2.$heading-size-4;
      line-height: 1.5;
    }
  }

  .app-native-app .learning-search-header {
    display: none;
  }

  .learning-search {
    background-color: #fff;
    padding-top: 1em;
    padding-bottom: 1em;
    margin-bottom: 2em;
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.2);
  }

  .learning-search-form__filters {
    position: relative;
    padding-top: 1px;

    h3 {
      padding-top: 0.5em;
      margin-right: 1em;
    }

    h3 span {
      visibility: hidden;
    }

    .field--specialties {
      width: 100%;
    }

    .learning-search-form__clear {
      position: absolute;
      right: 0;
      top: 0;
    }

    .fields {
      margin-top: 2em;
    }
  }
}

@include tools.media-query(lap-and-up) {
  .learning-search-header {
    position: relative;

    min-height: 240px;

    background: settings.$color-core-brand-first-blue-100
      url(/static/images/learning-lander-search-bg-desk.jpg) no-repeat 50% 50%;
    background-size: cover;
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;

    &:before {
      content: '';
      z-index: 1;
      position: absolute;
      width: 100%;
      top: 0;
      bottom: 0;
      background-color: rgba(settings.$color-core-brand-first-blue-100, 0.5);
      border-bottom-left-radius: 14px;
      border-bottom-right-radius: 14px;
    }

    h1 {
      position: relative;
      z-index: 2;
      color: #fff;
      width: 90%;
      max-width: 20em;
      margin: 0 auto;
      padding: 1em 0 1.5em;
      text-align: center;
      font-size: settings2.$heading-size-4;
      line-height: 1.5;
    }
  }

  .learning-search-form__filters {
    display: flex;
    flex-wrap: nowrap;
    h3 {
      margin: 1em 1em 0 0;
    }
    .learning-search-form__clear {
      margin-top: 1em;
      white-space: nowrap;
      width: 10em;
    }
  }

  .learning-search {
    position: relative;
  }

  .learning-search__body {
    z-index: 10;
    position: relative;
    width: 90%;
    padding: 0;
    margin: -130px auto 2em;
    form {
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.2);
      padding: 20px;
    }
  }

  .learning-search-form {
    position: relative;
    .fields {
      margin-bottom: 0;
    }

    .field--specialties {
      width: 30em;
      max-width: 100%;
    }

    .field.wide {
      width: 12em;
      max-width: 100%;
    }
  }

  .learning-search-form__submit {
    position: absolute;
    bottom: -1em;
    width: 10em;
    left: 50%;
    transform: translateX(-50%);
  }
}

@include tools.media-query(desk) {
  .learning-search-form {
    .field--specialties {
      width: 22em;
    }
    .field.wide {
      width: 9em;
    }
  }
}

@include tools.media-query(lap) {
  .learning-search-form {
    .field--specialties {
      width: 25em;
    }
    .field.wide {
      width: 12em;
    }
  }
}

.learning-benefit {
  display: flex;
  align-items: center;
  img {
    margin-right: 1em;
  }
}
