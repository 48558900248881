@use 'sass:math';

$footer-height: 74px;
$off-canvas-offset: -75%;

/* Settings */
@use 'sass:meta';
@use 'variables';

/* Tools */
@use 'tools.responsive';
@use 'tools.html5-input-types' as tools2;
@use 'tools.mixins' as tools3;
@use 'tools.hide' as tools4;

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @include meta.load-css('base.page');
  @include meta.load-css('base.template');
  @include meta.load-css('base.links');
  @include meta.load-css('base.forms');
}

@layer components {
  @include meta.load-css('objects.list-inline');
  @include meta.load-css('objects.buttons');
  @include meta.load-css('objects.images');

  @include meta.load-css('components.site-header');
  @include meta.load-css('components.site-footer');
  @include meta.load-css('components.cookie-consent');
  @include meta.load-css('components.homepage');

  @include meta.load-css('components.segment');

  @include meta.load-css('components.auth');
  @include meta.load-css('components.social-signin');

  @include meta.load-css('components.learning-page');

  @include meta.load-css('components.cms-page');

  @include meta.load-css('components.user-menu');
}

@include meta.load-css('objects.activity-indicator');
@include meta.load-css('objects.media');

@include meta.load-css('components.react-image-gallery');
@include meta.load-css('components.image-gallery');

@include meta.load-css('components.icons');
@include meta.load-css('components.modal');
@include meta.load-css('components.badge');
@include meta.load-css('components.dropdown');
@include meta.load-css('components.card');
@include meta.load-css('components.profile-basic');
@include meta.load-css('components.profile-list');
@include meta.load-css('components.autosuggest');
@include meta.load-css('components.tabs');

@include meta.load-css('components.comments');
@include meta.load-css('components.post');
@include meta.load-css('components.disclaimer');
@include meta.load-css('components.report-a-problem');
@include meta.load-css('components.poll');
@include meta.load-css('components.promo-link');
@include meta.load-css('components.social-share');
@include meta.load-css('components.alerts');

@layer utilities {
  @include meta.load-css('trumps.hide');
  @include meta.load-css('trumps.fix-webkit-autofill-color');
}
@include meta.load-css('trumps.clearfix');
