.modal__overlay {
  background-color: rgba(49, 69, 89, 0.95);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 250;
  display: flex;
  justify-content: space-around;
  overflow: hidden;
}

@keyframes modal-move-down {
  from {
    top: -80px;
  }
  to {
    top: 0;
  }
}

@keyframes modal-fade-in {
  from {
    opacity: 0;
    visibility: hidden;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
}

.modal-body {
  background: #fff;
  box-shadow: 0 0 0 0 transparent;
  color: #021740;
  animation: modal-fade-in 0.24s ease 0ms,
    modal-move-down 0.5s cubic-bezier(0.51, -0.1, 0.09, 1.44) 0ms;

  position: relative;
  max-height: 95%;
  vertical-align: middle;
  border-radius: 0;
  outline-color: rgb(171.5, 171.5, 171.5);
  outline-width: 0;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  align-self: center;
  -ms-overflow-style: none;
}

.modal-body--small {
  max-width: 600px;
  min-width: 340px;
  min-height: 113.3333333333px;
  background-color: #fff;
}
