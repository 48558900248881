@use 'settings.palette';
@use 'tools.responsive';
@use 'tools.mixins' as mixins;

.poll {
  clear: both;
  // allow space for "show results"
  padding-bottom: 1em;

  @include mixins.clearfix;
}

.resource section.poll h2,
.poll h2 {
  font-weight: bold;
  font-size: 100%;
  line-height: 1.25;
  @apply tw-mb-4;
}

.poll ol {
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

.poll__answer {
  background-color: #fff;
  border: 1px solid settings.$color-wireframe-grey-20;
  border-radius: 1px;
  color: settings.$color-text-grey-100;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px 10px;
  text-align: left;
  width: 100%;
  transition:
    background-color ease-in-out 0.26s,
    border-color ease-in-out 0.26s;
  position: relative;
}

button.poll__answer {
  color: settings.$light-link-color;
}
button.poll__answer:hover {
  background-color: rgba(0, 0, 0, 0.01);
  border-color: settings.$color-wireframe-grey-30;
}

.poll__answer-letter {
  font-weight: bold;
  position: relative;
}

.poll__answer-response {
  font-weight: bold;
  position: relative;
  text-align: right;
  span {
    display: block;
  }
}
.poll__answer-your-vote {
  @apply tw-text-xs;
}

.poll p {
  color: settings.$mid-text-color;
  margin: 0 0 10px;
}
.poll .smaller {
  display: block;
  font-size: 90%;
  margin-bottom: 0.25em;
}

.poll__answer-bar {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #a3c2ff;
}

.poll__answer--winner {
  @apply tw-text-ms-text-black;
}

.poll--expired .poll__answer--winner .poll__answer-bar {
  background-color: #a3c2ff;
}

.poll__answer-text {
  position: relative;
  z-index: 1;
  display: block;
  padding-right: 4em;
}

.poll__answer-answer {
  display: block;
  margin-left: 1.5em;
  flex-grow: 1;
}

.poll__score {
  display: block;
  text-align: right;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.poll__your_score {
  display: block;
  font-size: 0.8rem;
  color: settings.$color-core-brand-second-blue-80;
}

.poll__stats {
  color: settings.$mid-text-color;
}

.poll--edit .poll__answer {
  color: settings.$light-link-color;
}

.poll--extendOnly .poll__answer {
  color: #999;
}

.poll__answer input[type='text'] {
  background-color: transparent;
  border: 0;
  box-shadow: none;
  display: inline-block;
  margin-bottom: 0;
  padding: 5px 10px;
  width: 90%;
}

.poll__answer input[type='text']:focus {
  box-shadow: none;
}

.prepoll {
  background: settings.$color-core-brand-first-blue-100;
  padding-top: 15px;
  @apply tw-pb-4;
}

.cms-page .prepoll {
  position: absolute;
  top: 68px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
}

.cms-page .poll {
  .poll__answer--answered {
    border-color: #ccc;
    background-color: #f0f0f0;
  }
}

.prepoll-header {
  color: white;
  @apply tw-mb-4;
  h2 {
    margin: 0;
  }
}

.prepoll-content {
  background: settings.$content-background-color;
  @apply tw-p-4 tw-mb-8;
  border-radius: 1px;
  border: 5px solid rgba(218, 222, 225, 1);

  button.poll__answer {
    background-color: settings.$color-core-brand-second-blue-80;
    color: white;
    border-radius: 3px;
    transition: background-color 0.26s ease-in-out;
    &:hover {
      background-color: settings.$color-core-brand-second-blue-100;
    }
  }

  .poll {
    margin-bottom: 0;
  }

  .poll-stats {
    display: none;
  }
}

.prepoll-skip {
  text-align: right;
}

@include tools.media-query(palm) {
  .prepoll-header {
    @apply tw-py-0 tw-px-4;
  }
  .prepoll-content {
    @apply tw-mx-4;
  }
}

.poll__show-results {
  float: right;
  text-align: right;
}
