.icon-like g {
  stroke: #4b9bef;
  stroke-width: 0.8;
}

.icon-like--white g {
  stroke: #fff;
}

.icon-like--white.icon-like--liked g {
  stroke: #fff;
  fill: #fff;
}

.icon-like--liked g {
  stroke: #314559;
  fill: #314559;
}

.icon-like--hoverable:hover g {
  stroke: #1b6ebf;
  fill: #1b6ebf;
}

.icon-like--white.icon-like--hoverable:hover g {
  stroke: #fff;
  fill: #fff;
}
