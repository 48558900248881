$color-black: rgb(0, 0, 0);
$color-white: rgb(255, 255, 255);

$color-old-brand-background-blue: #09336a;
$color-old-brand-dark-blue: #021740;

$color-core-brand-first-blue-100: rgb(4, 0, 87);
$color-core-brand-first-blue-60: rgb(86, 81, 126);

$color-core-brand-second-blue-100: rgb(4, 99, 213);
$color-core-brand-second-blue-80: rgb(29, 115, 217);
$color-core-brand-second-blue-60: rgb(54, 130, 221);

$color-core-brand-supporting-light-blue-100: rgb(101, 154, 255);
$color-core-brand-supporting-light-blue-20: rgb(224, 235, 255);
$color-core-brand-supporting-light-blue-40: rgb(193, 215, 255);
$color-core-brand-supporting-light-blue-60: rgb(163, 194, 255);
$color-core-brand-supporting-light-blue-80: rgb(132, 174, 255);

$color-core-brand-accent-100: rgb(76, 167, 157);
$color-core-brand-accent-20: rgb(204, 249, 246);
$color-core-brand-accent-60: rgb(1, 227, 210);

$color-status-info: rgb(0, 98, 185);
$color-status-info-dark: rgb(0, 79, 148);
$color-status-success: rgb(8, 136, 5);
$color-status-success-light: rgb(111, 207, 151);
$color-status-alert: rgb(255, 185, 70);
$color-status-alert-light: rgb(255, 227, 181);
$color-status-error: rgb(211, 54, 49);
$color-status-error-light: rgb(252, 195, 189);

$color-text-black: rgb(30, 30, 30);
$color-text-grey-100: rgb(49, 69, 89);
$color-text-grey-80: rgb(117, 117, 128);
$color-text-grey-60: rgb(155, 154, 167);
$color-text-grey-40: rgb(197, 196, 210);
$color-text-grey-10: rgb(226, 225, 239);
$color-text-white: rgb(255, 255, 255);

$color-wireframe-note-yellow: rgb(255, 244, 180);
$color-wireframe-note-green: rgb(158, 240, 222);
$color-wireframe-note-blue: rgb(181, 218, 247);

$color-wireframe-grey-90: rgb(26, 26, 26);
$color-wireframe-grey-80: rgb(51, 51, 51);
$color-wireframe-grey-70: rgb(77, 77, 77);
$color-wireframe-grey-60: rgb(102, 102, 102);
$color-wireframe-grey-50: rgb(102, 102, 102);
$color-wireframe-grey-40: rgb(153, 153, 153);
$color-wireframe-grey-30: rgb(179, 179, 179);
$color-wireframe-grey-20: rgb(204, 204, 204);
$color-wireframe-grey-10: rgb(230, 230, 230);
$color-wireframe-grey-5: rgb(242, 242, 242);

$base-text-color: $color-text-black;
$base-background-color: #f8f8ff;
$content-background-color: #fff;

$mid-text-color: $color-text-grey-100;
$low-text-color: $color-text-grey-40;

$off-white: $color-text-grey-10;

$link-color: $color-core-brand-second-blue-100;
$light-link-color: $color-core-brand-supporting-light-blue-80;
