@use 'settings.palette';

.admin-alert {
  background-color: #09336a;
  color: #fff;

  padding: 5px 1em;
  background: red;
  color: white;
  font-weight: 700;
  text-align: center;

  a {
    color: settings.$light-link-color;
  }
}

.admin-alert + .admin-alert {
  margin-top: 1px;
}

.admin-alert--high {
  background-color: red;
  color: white;

  a {
    color: yellow;
  }
}

.admin-alert--low {
  background-color: #e5e5e5;
  color: #555;

  a {
    color: settings.$link-color;
  }
}
